$fontFamily = 'Noto Sans TC', '黑體-繁', 'Apple LiGothic Medium', 'PingFang', '微軟正黑體', 'Microsoft JhengHei', 'WenQuanYi Zen Hei', helvetica, 'Arial', sans-serif
$kvMedia = 'screen and (max-width 1700px)'
$desktop = 'screen and (max-width 1280px)'
$tablet = 'screen and (max-width 1024px)'
$mobile = 'screen and (max-width 767px)'
$fourInch = 'screen and (max-width 374px)'
$lastMedia = 'screen and (max-width 320px)'
$gold = #A48968
$green = #1F3D10
$grey = #A0A0A0
$pink = #b7004f

[v-cloak]
  display none

*
  box-sizing border-box

::selection
  color white
  background $pink

html
  font-family $fontFamily
  font-size 16px
  font-weight 300
  letter-spacing 1px
  overflow-x hidden
  background black
  color white

body
  margin 0
  padding 0
  position relative

a
  text-decoration none
  color $gold

  &:focus
    outline 0

.container
  width 1024px
  margin 0 auto
  max-width 100%

  @media $desktop
    padding 0 20px

  &.container-column
    display flex
    flex-flow row nowrap
    align-items flex-end

    @media $mobile
      flex-flow column wrap

    > .column
      width 50%

      @media $mobile
        width 100%

    .column-contact
      width calc(100% - 530px)
      padding-right 30px

      @media $mobile
        width 100%
        padding-right 0

    .column-maps
      width 530px

      @media $mobile
        width 100%
        position relative
        padding-bottom 75%
        height 0
        overflow hidden

        iframe
          position absolute
          top 0
          left 0
          width 100% !important
          height 100% !important

.btn
  display inline-block
  cursor pointer

  &-more
    text-transform uppercase
    color white
    font-size 24px
    width 240px
    line-height 54px
    border-radius 27px
    border 1px solid white
    text-align center
    position relative
    text-indent -10px
    transition 0.3s linear all
    margin 0 10px

    @media $mobile
      font-size 18px
      line-height 2.25

    svg
      position absolute
      top 50%
      transform translateY(-50%)
      right 20px
      stroke white
      width 11px
      height 21px

    &:hover
      background white
      color black

      svg
        stroke black

    &--black
      border-color black
      color black

      svg
        stroke black

      &:hover
        border-color white
        color white
        background black

        svg
          stroke white

    &--left
      text-indent 10px

      svg
        right inherit
        left 20px
        transform rotate(180deg) translateY(50%)
        transform-origin center

    &--sm
      width 175px
      line-height 2
      font-size 20px

      @media $mobile
        font-size 16px

        svg
          width 8px
          height 12px

    &.btn-green
      color $green
      background white

.button-holder
  display flex
  justify-content center

  &--gap
    padding 110px 0

    @media $desktop
      padding 60px 0

    @media $mobile
      padding 40px 0

.logo
  font-size 0

  svg
    width 187px

    @media $desktop
      width 140px

    @media $tablet
      width 100px

    @media $mobile
      width 85px

.header
  padding 46px 75px
  display flex
  flex-flow row nowrap
  justify-content space-between
  align-items flex-end
  text-transform uppercase
  font-size 24px
  color white
  position absolute
  top 0
  left 0
  width 100%
  z-index 2

  @media $kvMedia
    font-size 20px

  @media $desktop
    font-size 16px

  @media $tablet
    font-size 15px
    padding 45px 20px

  @media $mobile
    padding 0 20px
    height 60px
    background black
    align-items center
    position fixed
    font-size 30px

.kv
  &-subtitle
    font-size 23px
    font-weight 400
    margin 0 0 40px

    @media $mobile
      font-size 18px
      margin-bottom 15px
      text-align center

    @media $lastMedia
      font-size 16px
      margin-bottom 5px

  &-title
    font-weight 400
    font-size 60px
    margin 0 0 40px
    text-transform uppercase
    letter-spacing 5px

    @media $mobile
      margin-bottom 15px
      font-size 30px

  &-bg
    width 100%
    height 600px
    background-repeat no-repeat
    background-size cover
    background-position center
    position relative

    &::after
      content ''
      position absolute
      top 0
      left 0
      width 100%
      height 100% !important
      backdrop-filter brightness(0.5)

    @media $mobile
      height 200px
      background-size 200%
      background-position bottom center

    .container
      padding-top 200px
      height 600px
      display flex
      align-items center
      justify-content center
      flex-flow column nowrap
      width 100%
      position relative
      z-index 1
      visibility hidden

      @media $mobile
        padding-top 20px
        height 200px

.kv-slider
  @media $mobile
    img
      height 360px

.menu-toggler
  display none

  @media $mobile
    display block

  .toggler
    width 60px
    height 60px
    position relative
    right -10px
    display flex
    align-items center
    justify-content center

  .open
    svg
      width 45px

    .menu-open &
      display none

  .close
    display none

    svg
      width 25px

    .menu-open &
      display flex

.intl
  color white
  line-height 40px
  border 1px solid white
  border-radius 35px
  width 70px
  margin 0 10px
  text-align center
  display inline-block
  transition 0.3s linear all

  &:first-child
    margin-left 0

  &:last-child
    margin-right 0

  &:hover, &.active
    background white
    color black

  @media $kvMedia
    line-height 34px
    width 60px

  @media $desktop
    width 40px
    font-size 16px
    line-height 1.6
    border-radius 16px

  @media $tablet
    margin 0 5px

  @media $mobile
    font-size 14px

  &-holder
    @media $mobile
      display none
      position absolute
      right 70px
      top 0

      .menu-open &
        display block

.slick-dots
  bottom 35px

  @media $mobile
    bottom 15px

  li
    height 28px
    margin 0 3px

    button
      height 28px
      padding 0 5px

      &::before
        width 4px
        height 18px
        content ''
        background white
        transition 0.3s linear height
        transform-origin center
        left 50%
        top 50%
        transform translate(-50%, -50%)
        will-change height

    &.slick-active
      button
        &::before
          height 28px

.slick-dotted.slick-slider
  margin-bottom 0

.slick-slide:focus
  outline 0

.navigation
  @media $mobile
    display none
    position absolute
    left 0
    top 60px
    flex-flow column wrap
    width 100%
    background-color rgba(0, 0, 0, 0.8)
    align-items center
    padding 10px 0

    .menu-open &
      display flex

  &-list
    color white
    margin 0 30px
    position relative
    transition 0.3s linear color
    font-weight 400

    @media $kvMedia
      margin 0 15px

    @media $desktop
      margin 0 10px

    @media $tablet
      margin 0 5px

    @media $mobile
      margin 0 15px
      padding 10px 0
      width calc(100% - 30px)
      text-align center
      border-bottom 1px solid white

      &:last-child
        border-bottom 0

    &:hover, &.active
      color $gold

      &::after
        width 100%

        @media $mobile
          display none

    &::after
      position absolute
      bottom -10px
      left 0
      width 0
      height 5px
      background $gold
      content ''
      transition width 0.3s linear

.section-kv
  position relative

  @media $mobile
    margin-top 60px

  &::before
    content ''
    display block
    position absolute
    top 0
    left 0
    width 100%
    height 320px
    background linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)
    z-index 1
    pointer-events none

    @media $mobile
      background linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)
      height 100px

  &.section-index
    &::before
      height 600px

      @media $desktop
        height 400px

      @media $mobile
        display none

.section-white--mb
  @media $mobile
    color black
    background white

.section-quote
  padding 100px 0

  @media $mobile
    padding 60px 0

  .quote-text
    font-size 18px
    line-height 2
    margin 50px 0 70px
    text-align justify

    @media $mobile
      font-size 15px
      line-height 1.75
      margin 40px 0

  .quote-author
    font-size 28px
    text-align right
    margin-top 65px

    @media $mobile
      margin-top 40px
      font-size 14px

  blockquote
    line-height 2.2
    text-align center
    margin 0
    font-size 34px

    [data-lang='en'] &
      font-size 30px
      font-weight bold

    @media $desktop
      font-size 30px

      [data-lang='en'] &
        font-size 24px

    @media $mobile
      font-size 16px
      font-weight 500

.slick-btn--custom
  background transparent
  border 0
  outline 0
  cursor pointer
  opacity 0.6
  transition opacity 0.3s linear
  position absolute
  top 50%
  transform translateY(-50%)

  &.btn-prev
    right calc(100vw - 300px)

    @media $tablet
      left 40px
      right inherit

    @media $mobile
      left 50px

  &.btn-next
    left calc(100vw - 300px)

    @media $tablet
      left inherit
      right 40px

    @media $mobile
      right 50px

  &:hover
    opacity 1

  svg
    width 47px
    height 91px

    @media $mobile
      width 20px

.section-poster
  position relative
  padding-bottom 75px

  &::after
    width 100%
    height 418px
    content ''
    display block
    position absolute
    left 0
    bottom 0
    background $green
    z-index -1

.section-gap
  padding 140px 0

  @media $desktop
    padding 70px 0

  @media $mobile
    padding 40px 0

.section-title
  margin 0 0 70px
  font-weight 400
  text-transform uppercase
  text-align center
  font-size 60px

  @media $desktop
    font-size 45px

  @media $mobile
    font-size 33px
    letter-spacing 0
    margin-bottom 35px

  &.section-subtitle
    font-size 50px
    margin-bottom 40px
    text-align left

    @media $mobile
      text-align center
      font-size 33px
      margin-bottom 20px

.about-info
  font-size 18px
  line-height 2
  margin 0
  text-align justify

  @media $mobile
    font-size 14px

.member
  cursor pointer

  &-modal
    &__description
      font-weight 400
      margin 0 0 20px

    &__title
      font-weight 400
      margin 0 0 10px
      font-size 16px
      display none

      @media $mobile
        margin-bottom 0

    &__info
      @media $mobile
        padding-top 20px

    &__paragraph
      margin 0 0 10px
      line-height 1.75

    &__table, &__paragraph, &__awards
      font-size 12px
      display none

      @media $mobile
        font-size 10px

    &__table
      &--50
        width calc(50% - 4px)
        display inline-table

      td
        padding 0 5px

        &:first-child
          padding-left 0

        &:nth-child(2)
          min-width 60px

    &__name
      margin 0 0 5px
      font-size 22px
      font-weight 400

      &--en
        color $gold
        font-size 14px
        font-weight 300
        margin 0 0 20px

    &__holder
      display flex
      flex-flow row wrap
      padding 65px 70px 40px

      @media $mobile
        padding 40px 10px
        flex-flow column wrap
        overflow auto

      .button-holder
        width 100%
        padding 50px 0 0

    &__content
      width calc(100% - 300px)
      overflow auto
      height 382px

      &::-webkit-scrollbar-track
        box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
        border-radius 10px
        background-color #F5F5F5

      &::-webkit-scrollbar
        width 8px
        background-color #F5F5F5

      &::-webkit-scrollbar-thumb
        border-radius 10px
        box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
        background-color $gold

      @media $mobile
        width 100%

        [class^='member-modal__name']
          display none

    &__image
      width 300px
      padding-right 15px

      img
        max-width 100%

      @media $mobile
        width 100%
        padding-right 0
        display flex
        flex-flow row nowrap
        align-items flex-end

        img
          width 180px
          margin-right 10px

      .member-modal__info
        display none

        .member-modal__name--en
          margin-bottom 0

        @media $mobile
          display block

  &-img
    font-size 0
    position relative
    background-repeat no-repeat
    background-size cover
    background-position center

    &::before
      content ''
      padding-bottom calc(312 / 235 * 100%)
      display block

    &:hover
      .eq-holder
        visibility visible
        opacity 1

    .eq-holder
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      background rgba(0, 0, 0 0.5)
      opacity 0
      visibility hidden
      transition 0.2s linear all
      display flex
      justify-content center
      align-items center
      place-items center

    img
      width 100%
      max-width 235px

  &-holder
    display grid
    grid-gap 50px 28px
    grid-template-columns repeat(auto-fit, 235px)
    justify-content center
    margin-bottom 110px

    @media $desktop
      margin-bottom 60px

    @media $mobile
      grid-gap 20px
      margin-bottom 40px
      grid-template-columns repeat(auto-fit, calc(50% - 10px))

    &__type
      margin 0 0 30px
      font-size 30px
      text-align center
      font-weight 400

  &-name
    font-size 22px
    margin 12px 0 5px
    font-weight 400
    color white

    @media $mobile
      font-size 18px
      margin 10px 0 5px
      color black

      .section-members &
        color white

    &--en
      font-size 14px
      color $gold

      @media $mobile
        font-size 12px

.contact-info
  font-size 14px
  line-height 1.7

  @media $mobile
    text-align left

.poster
  position relative
  font-size 0
  max-width 325px
  cursor pointer
  margin 0 15px

  img
    max-width 100%
    width 325px
    height 486px
    object-fit cover

  &::after
    content ''
    position absolute
    z-index 1
    width 100%
    height 100%
    background rgba(0, 0, 0, 0.5)
    left 0
    top 0
    opacity 0
    visibility hidden
    transition 0.3s linear all

  &:focus
    outline 0

  &:hover
    .poster-details
      opacity 1
      visibility visible

    &::after
      visibility visible
      opacity 1

  &-slick
    position relative

  &-tag
    border 1px solid white
    border-radius 16px
    font-size 15px
    padding 6px 10px
    line-height 1.1
    display inline-block
    margin 0 2px
    text-transform uppercase

    &__holder
      display flex

    &:first-child
      margin-left 0

    @media $mobile
      font-size 11px
      padding 0 4px
      border-radius 20px
      line-height 23px

      .slick-slide &
        font-size 16px
        padding 0 14px
        line-height 28px

    &::before
      content '#'

  &-title
    font-size 24px
    margin 5px 0 18px
    font-weight 400

    @media $mobile
      font-size 18px
      margin 0 0 10px

      .slick-slide &
        font-size 24px
        margin-bottom 12px

  &-year
    width 100%
    display inline-block
    font-size 20px

    @media $mobile
      font-size 16px

      .slick-slide &
        font-size 20px

  &-holder
    margin-bottom 130px

    @media $mobile
      margin-bottom 60px

  &-details
    font-size 20px
    position absolute
    width 100%
    bottom 25px
    left 0
    padding 0 22px
    opacity 0
    visibility hidden
    transition all 0.3s linear
    z-index 2
    color white

    @media $mobile
      bottom 0
      padding 0 8px 10px

      .slick-slide &
        padding 0 15px 20px

.section-gold
  background $gold

.section-white
  background white
  color black

.section-grey
  background $grey
  color black

.section-contact
  @media $mobile
    background black
    color white

.section-facilities
  position relative

  &::after
    position absolute
    content ''
    bottom -100px
    right 0
    width 375px
    height 434px
    background url('../img/mic.png')
    background-position center
    background-size cover
    background-repeat no-repeat

    @media $kvMedia
      width 180px
      height 210px
      bottom -50px

    @media $desktop
      width 100px
      height 115px

    @media $mobile
      display none

  &--inner
    &::after
      display none

.facilities
  &-slick
    position relative

  &-info
    padding-top 110px
    font-size 18px
    line-height 2
    letter-spacing 0

    @media $mobile
      padding-top 20px
      font-size 14px
      text-align justify

  &-controls
    height 100%

  &-holder
    img
      margin 0 30px
      width 900px
      height 506px
      object-fit cover

      @media $mobile
        margin 0 5px
        width 325px
        height 184px

.license-holder
  padding 60px 0
  text-align center

  img
    width 100%
    max-width 350px

  @media $mobile
    padding 30px 20px

    img
      width 200px

.section-license
  .container
    display flex
    flex-flow row wrap

    .license-holder
      padding 0

      @media $tablet
        padding-bottom 40px

        &:last-child
          padding-bottom 0

      @media $mobile
        width 100%

      &:first-child
        padding-right 60px

        @media $mobile
          padding-right 0

  img
    height 60px
    width auto
    margin-right 20px

    &:last-child
      margin-right 0

    @media $mobile
      height 45px
      margin-bottom 10px
      margin-right 10px

  .license-title
    font-size 18px
    margin-bottom 40px
    text-align left

    @media $mobile
      text-align center
      width 100%
      margin-bottom 20px

.sort-tools
  display flex
  flex-flow row wrap
  justify-content center
  margin-bottom 60px

  @media $mobile
    margin-bottom 10px

  span
    display inline-block
    padding 0 30px
    margin-bottom 10px
    border-right 1px solid white
    color white
    cursor pointer
    transition color 0.3s linear
    font-size 24px

    @media $mobile
      color black
      border-right-color black
      font-size 14px
      padding 0 8px
      font-size 16px

    &:hover, &.active
      color $gold

    &:first-child
      border-left 1px solid white

      @media $mobile
        border-left-color black

.gallery
  font-size 0
  filter grayscale(1)
  transition 0.3s linear filter
  will-change filter
  cursor pointer
  background-position center
  background-repeat no-repeat
  background-size cover
  position relative

  &::after
    content ''
    padding-bottom calc((100% * 1 / 1))
    display block
    width 100%

  &:hover
    filter grayscale(0)

  &-holder
    display grid
    grid-gap 2px 2px
    grid-template-columns repeat(3, 340px)
    justify-content center

    @media $desktop
      grid-template-columns repeat(3, 325px)

    @media $tablet
      grid-template-columns repeat(3, 280px)

    @media $mobile
      grid-template-columns repeat(3, 1fr)

.movie
  position relative
  font-size 0
  background-repeat no-repeat
  background-size cover
  background-position center
  cursor pointer
  z-index 1

  &-wrapper
    display grid
    grid-gap 10px 8px
    grid-template-columns repeat(auto-fit, 250px)
    justify-content center
    padding 30px 0

    @media $mobile
      grid-template-columns repeat(2, 1fr)

    @media $lastMedia
      grid-template-columns repeat(auto-fit, 145px)

    img
      max-width 100%
      width 250px
      height 340px
      object-fit cover

      @media $mobile
        width 170px
        height 240px

      @media $lastMedia
        width 145px
        height 195px

  &:hover, &:active, &:focus, &.active
    .poster-details, &::after
      opacity 1
      visibility visible

  &::before
    content ''
    display block
    padding-bottom calc(340 / 250 * 100%)
    pointer-events none

  &::after
    content ''
    position absolute
    z-index 1
    width 100%
    height 100%
    background rgba(0, 0, 0, 0.5)
    left 0
    top 0
    opacity 0
    visibility hidden
    transition 0.3s linear all

.video
  &-text
    text-align center
    font-size 20px
    margin 0 0 30px

    @media $mobile
      font-size 18px
      margin-bottom 20px

  &-holder
    display grid
    grid-gap 15px 26px
    grid-template-columns repeat(auto-fit, 325px)
    justify-content center

    @media $mobile
      display block

    .video
      font-size 0
      cursor pointer

      &-image__wrapper
        position relative

        &:hover
          &::before
            background-color transparent

          &::after
            visibility hidden
            opacity 0

        &::before
          position absolute
          top 0
          left 0
          width 100%
          height 100%
          background-color rgba(0, 0, 0, 0.4)
          content ''
          transition 0.3s ease background-color

        &::after
          content ''
          position absolute
          top 50%
          left 50%
          transform translate(-50%, -50%)
          background-image url('../img/play.png')
          background-size 100%
          background-repeat no-repeat
          width 64px
          height 64px
          transition 0.3s ease all

      img
        width 325px
        height 184px
        object-fit cover

        @media $mobile
          width 100%
          height inherit

      @media $mobile
        margin 0 5px

  &-title
    font-size 16px
    font-weight 300
    text-align center
    color black
    margin 20px 0

    @media $mobile
      margin 15px 0

.about-poster
  &__title
    font-size 30px
    margin 0 0 50px
    text-align center
    font-weight 400

  &__holder
    display grid
    grid-gap 0
    grid-template-columns repeat(auto-fit, 204px)
    justify-content center

    @media $mobile
      grid-template-columns repeat(3, 1fr)

.about-bio
  display flex
  flex-flow row nowrap
  margin-bottom 80px

  @media $mobile
    flex-flow column wrap

  .bio-img
    width 235px
    font-size 0

    @media $mobile
      width 100%

    img
      width 100%

  .bio-title
    margin 0 0 15px
    font-size 30px
    font-weight 400
    line-height 1.25

    @media $mobile
      text-align center
      font-size 24px

  .bio-info
    width calc(100% - 235px)
    padding-left 40px
    font-size 18px
    letter-spacing 0
    line-height 1.9
    text-align justify

    @media $mobile
      width 100%
      padding 30px 0
      font-size 16px

.section-footer
  text-align center
  padding 50px 0
  line-height 1.75

  .contact-info
    margin 0

    @media $mobile
      margin 0 0 20px

  .container
    .column-contact
      width 100%

  .section-title, .column-maps
    display none

    @media $mobile
      display block

  @media $mobile
    font-size 14px
    background black
    color white
    padding-bottom 0
    padding-top 30px

.section-footer, .section-contact
  a
    color black

    @media $mobile
      color white

    &:hover
      color $gold
      text-decoration underline

.footer
  color white
  font-size 16px

  @media $mobile
    font-size 12px

  .container
    display flex
    justify-content space-between
    align-items center
    height 130px

    @media $mobile
      height 60px

  &-share
    width 50px
    height 50px

    @media $mobile
      width 30px
      height 30px

    svg
      width 100%

.jquery-modal
  z-index 2
  padding 0

  &.blocker
    z-index 2

  .modal
    border-radius 0
    width 1024px
    max-width 100%
    color black
    padding 0

    a
      &.close-modal
        background-image url('../img/close.svg')
        background-size 100%
        width 32px
        height 32px
        top 30px
        right 30px

        @media $mobile
          width 25px
          height 25px
          top 20px
          right 20px

.bar
  display inline-block
  width 4px
  background white
  margin 0 4px
  will-change height

  .section-white--mb &
    @media $mobile
      background black

  &:nth-child(1), &:nth-child(5)
    height 18px

  &:nth-child(2), &:nth-child(4)
    height 28px

  &:nth-child(3)
    height 46px

  .bar-animation &
    &:nth-child(1)
      animation music1 0.5s linear infinite alternate

    &:nth-child(5)
      animation music1 0.5s linear 0.3s infinite alternate

    &:nth-child(2)
      animation music2 0.4s linear infinite alternate

    &:nth-child(4)
      animation music2 0.5s linear 0.4s infinite alternate

    &:nth-child(3)
      animation music 0.3s linear infinite alternate

  &.bar
    animation none

.crew
  &-title
    color $gold
    font-weight 500

  &-holder
    line-height 1.7
    margin-bottom 5px

.work-modal
  &__holder
    display flex
    flex-flow row nowrap

    @media $mobile
      flex-flow column nowrap
      justify-content center
      align-items center

  &__year
    margin-bottom 15px
    font-size 16px

  &__info
    width calc(100% - 430px)
    padding 0 40px
    font-size 12px
    max-height 584px
    overflow auto

    @media $desktop
      padding 0 20px

    @media $tablet
      width calc(100% - 370px)

    @media $mobile
      width 100%
      padding 20px 0
      max-height inherit

  &__tag
    margin-bottom 20px

    span
      display inline-block
      font-size 16px

      &:nth-child(2)
        margin-left 10px

  &__title
    font-size 24px
    margin-bottom 5px
    font-weight 500

  &__movie
    width 430px

    @media $tablet
      width 370px

    @media $mobile
      width 100%

    &:hover
      cursor unset

      &::after
        display none

.observer
  padding 30px 0
  display flex
  flex-flow column wrap
  justify-content center
  align-items center
  font-weight 400
  transform-origin top center
  animation pulse 2s infinite
  will-change transform

  span
    text-transform uppercase
    font-size 18px

.loading, .equalizer
  display flex
  justify-content center
  width 60px
  height 48px

  @media $mobile
    transform-origin top center
    transform scale(0.75)

.loading
  align-items flex-start
  margin-top 10px

.equalizer
  align-items center

.go-top
  position fixed
  background $gold
  color white
  right 50px
  bottom 50px
  border-radius 50%
  width 80px
  height 80px
  display flex
  align-items center
  justify-content center
  flex-flow column nowrap
  cursor pointer
  opacity 0.7
  transition 0.3s ease opacity
  box-shadow 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)

  @media $mobile
    right 15px
    bottom 25px
    width 50px
    height 50px
    font-size 12px
    opacity 1

  @media (any-hover hover)
    &:hover
      opacity 1

      .bar
        &:nth-child(1)
          animation music1 0.5s linear infinite alternate

        &:nth-child(5)
          animation music1 0.5s linear 0.3s infinite alternate

        &:nth-child(2)
          animation music2 0.4s linear infinite alternate

        &:nth-child(4)
          animation music2 0.5s linear 0.4s infinite alternate

        &:nth-child(3)
          animation music 0.3s linear infinite alternate

  .equalizer
    transform scale(0.6)
    transform-origin center

    @media $mobile
      transform scale(0.4)

  .top
    display inline-block
    transform translateY(-8px)

    @media $mobile
      transform translateY(-12px)

@keyframes music1
  0%
    height 8px

  100%
    height 18px

@keyframes music2
  0%
    height 18px

  100%
    height 28px

@keyframes music
  0%
    height 42px

  100%
    height 46px

@keyframes pulse
  0%
    transform scale(0.95)
    opacity 0.5

  70%
    transform scale(1)
    opacity 1

  100%
    transform scale(0.95)
    opacity 0.5

.lity
  background rgba(255, 255, 255, 0.9)

  &-close
    background-image url('../img/close.svg')
    background-size 100%
    background-repeat no-repeat
    text-indent -9999px
    width 32px
    height 32px
    top 30px
    right 30px

    @media $mobile
      width 25px
      height 25px
      top 20px
      right 20px

    @media (orientation landscape)
      display none

.modal-mask
  position fixed
  z-index 9998
  top 0
  left 0
  width 100%
  height 100%
  background-color rgba(0, 0, 0, 0.5)
  display table
  transition opacity 0.3s ease

.modal-wrapper
  display table-cell
  vertical-align middle

.modal-container
  width 100%
  max-width 1024px
  margin 0px auto
  color black
  padding 65px 70px 40px
  background-color #fff
  border-radius 2px
  box-shadow 0 2px 8px rgba(0, 0, 0, 0.33)
  transition all 0.3s ease
  position relative
  max-height 100vh
  overflow auto

  @media $desktop
    padding 55px 40px 40px

  @media $mobile
    padding 50px 20px

.modal-header h3
  margin-top 0
  color #42b983

.modal-default-button
  float right

.modal-close
  position absolute
  display block
  text-indent -9999px
  background-repeat no-repeat
  background-image url('../img/close.svg')
  background-size 100%
  width 32px
  height 32px
  top 30px
  right 30px
  cursor pointer

  @media $mobile
    width 25px
    height 25px
    top 20px
    right 20px

.modal-enter
  opacity 0

.modal-leave-active
  opacity 0

.modal-enter .modal-container, .modal-leave-active .modal-container
  -webkit-transform scale(1.1)
  transform scale(1.1)
